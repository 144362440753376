<template></template>
<script type="plain/text">
import GoogleService from "@/scripts/services/google.service";
import LoginResultType from "@/scripts/models/enums/loginResultType.enum";
import LoginService from "@/scripts/services/login.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { LoginSocialMedia } from "@/scripts/models/enums/socialMedias.enum.js";
import UserService from "@/scripts/services/user.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import CampaignService from "@/scripts/services/campaign.service";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      userService: new UserService(),
      loginService: new LoginService(),
      campaignService: new CampaignService(),
      campaign: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    let subdomain = CommonHelper.getSubdomain() || "";

    let user = JSON.parse(
      '{"' +
        decodeURI(window.location.hash.replace("#", ""))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    user.state = user.state || "";

    if (subdomain !== user.state) {
      if (subdomain !== "") {
        subdomain += ".";
      }
      window.location.href = window.location.href.replace(
        window.location.protocol + "//" + subdomain,
        window.location.protocol + "//" + user.state + "."
      );
      next(false);
    } else {
      next();
    }
  },
  created() {
    if (window.location.hash) {
      let user = JSON.parse(
        '{"' +
          decodeURI(window.location.hash.replace("#", ""))
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      this.loginService
        .googleLogin(user.access_token)
        .then(this.onGoogleLoginCheckCallback.bind(this))
        .catch(this.onLoginErrorCallback.bind(this));

      this.campaignService.findBySubdomain((data) => {
        this.campaign = data;
      });
    }
  },
  methods: {
    ...mapActions([
      "setLogged",
      "setReloadCart",
      "setSocialMediaLogin",
      "setMissingInformation",
    ]),
    onGoogleLoginCheckCallback(googleLoginResponse) {
      if (googleLoginResponse.signedIn == LoginResultType.Success) {
        this.$store.commit("REMOVE_ROLES");
        this.userService.listRolesOfLoggedUser().then(
          function (data) {
            this.$store.commit("SET_ROLES", data);
            this.setLogged(true);
            this.setReloadCart(true);

            if (this.campaign.modalConfirmationOnDonationActive) {
              this.$nextTick(() => {
                this.$store.commit("SHOW_PERSON_INFO");
              });
            }

            if (this.$router.currentRoute.name == Routes.web.Login) {
              this.$router.push({ name: Routes.app.DashboardPF });
            } else {
              window.location = window.location.origin + "/carrinho";
            }
          }.bind(this)
        );
      } else if (googleLoginResponse.signedIn == LoginResultType.MissingInfo) {
        var payload = {
          registerStep: 5,
          missingPersonInfo: googleLoginResponse.missingPersonInfo,
        };
        this.setMissingInformation(payload);
        window.location = window.location.origin + "/carrinho";
      } else {
        var payload = {
          registerStep: 4,
          socialMediaUser: googleLoginResponse.profileData,
          socialMedia: LoginSocialMedia.Google,
        };
        this.setSocialMediaLogin(payload);
        window.location = window.location.origin + "/carrinho";
      }
    },
    onLoginErrorCallback(err) {
      if (err && err.errorMessage) {
        //alert(err.errorMessage);
        CommonHelper.swal("", err.errorMessage, "error");
      }

      this.$router.push({ name: Routes.web.Login });
    },
  },
};
</script>
